import React, { useEffect, useState, useRef } from "react";
import { Form } from "react-formio";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  emptyQuestionsFormDataList,
  getQuestionsFormList,
  getsubmitForm,
  submitForm,
} from "../../../redux/questionaries/questionariesAction";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "../../../components/Loader/Loader";
// import CustomDialog from "../../../utils/CustomDialog";
/* eslint-disable react-hooks/exhaustive-deps */

const italianMonths = [
  { value: "", label: "mm" },
  { value: 1, label: "Gennaio" },
  { value: 2, label: "Febbraio" },
  { value: 3, label: "Marzo" },
  { value: 4, label: "Aprile" },
  { value: 5, label: "Maggio" },
  { value: 6, label: "Giugno" },
  { value: 7, label: "Luglio" },
  { value: 8, label: "Agosto" },
  { value: 9, label: "Settembre" },
  { value: 10, label: "Ottobre" },
  { value: 11, label: "Novembre" },
  { value: 12, label: "Dicembre" },
];

const updateFormioComponent = (formRef) => {
  if (formRef?.current?.formio?.components) {
    const components = formRef.current.formio.components;

    for (const component of components) {
      if (
        component?.component?.key === "m_data_nasc" ||
        component?.component.key === "p_data_nasc" ||
        component?.component?.key === "qualELaDataPresuntaDelParto" ||
        component?.component?.key === "data_mestr" ||
        component?.component?.key === "data_presunt" ||
        component?.component?.key === "b_data_nasc" ||
        component?.component?.key === "b_data_nasc" ||
        component?.component?.key === "data_ritorno"
      ) {
        component._months = italianMonths;
        formRef.current.formio.redraw();
      }
    }
  } else {
    console.log("Formio components not found.");
  }
};

const FormRender = ({ t }) => {
  const { id } = useParams();
  const currentData = new Date();
  const indexValue = localStorage.getItem("index") || 0;
  const currentLang = useSelector((state) => state?.AuthReducer?.lang);
  const userDetails = useSelector((state) => state?.UserReducer?.userDetails);
  const questionariesList = useSelector(
    (state) => state?.QuestionariesReducer?.questionariesList
  );

  const formRef = useRef(null);
  const formData =
    useSelector(
      (state) => state?.QuestionariesReducer?.questionsFormDataList?.data
    ) || [];
  const getformData =
    useSelector(
      (state) => state?.QuestionariesReducer?.questionsFormDataListpre
    ) || [];
  const [state, setState] = useState({});
  const [Update, setUpdate] = useState({});
  const [sectionIndex, setSectionIndex] = useState();
  const [loading, setLoading] = useState(true);
  // const [showDialog, setShowDialog] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const handleClose = () => setShowDialog(false);

  // const handlePrev = () => {
  //   setShowDialog(false);
  //   // window.scrollTo(0, 0);
  // };

  useEffect(() => {
    if (formData && formData.length > 0 && Object.keys(state).length === 0) {
      const findIndex = formData
        ?.sort((a, b) => a.sectionNo - b.sectionNo)
        .findIndex(
          (ele) =>
            ele.isCompleted === false && ele?.masteranswers?.unLock === false
        );
      const checkData =
        indexValue === "0" ? findIndex : indexValue === "-1" ? "0" : indexValue;
      if (
        (findIndex === -1 || findIndex === 0) &&
        (checkData === "-1" || indexValue === "0")
      ) {
        setState(formData[0]);
        setSectionIndex(0);
        setLoading(false);
      } else {
        setState(formData[checkData]);
        setSectionIndex(checkData);
        setLoading(false);
      }
    }
  }, [currentLang, formData, indexValue]);

  useEffect(() => {
    if (state && state?.questions) {
      const ChangeLable = JSON.parse(state?.questions);
      const isLastSection =
        (state?.sectionName === "Domande finali" && state?.sectionNo === 10) ||
        (state?.sectionName === "Stile di vita" &&
          state?.sectionNo === 7 &&
          state.formTitle === "Questionario Gravidanza") ||
        (state?.sectionName === "Il Tuo Stato D'Animo" &&
          state?.sectionNo === 4 &&
          state?.formTitle === "Nascita");
      // Update button label and visibility
      ChangeLable.components[ChangeLable.components.length - 1].label =
        isLastSection ? "" : "Salva ed esci"; // Set empty label if last section
      ChangeLable.components[ChangeLable.components.length - 1].hidden =
        isLastSection; // Hide button if last section

      if (sectionIndex === 0) {
        ChangeLable.components[ChangeLable.components.length - 1].label =
          formData?.length === state?.sectionNo
            ? "Salva ed esci"
            : "Salva ed esci";
        let addedData = ChangeLable?.components?.map((ele) => {
          switch (ele.key) {
            case "m_nome":
              return {
                ...ele,
                defaultValue: userDetails?.firstName,
              };
            case "m_cogn":
              return {
                ...ele,
                defaultValue: userDetails?.surName,
              };
            case "m_data_nasc":
              return {
                ...ele,
                defaultValue:
                  userDetails?.birthDate === undefined
                    ? ""
                    : moment(userDetails?.birthDate).format("DD/MM/YYYY"),
              };

            case "m_cod_fisc":
              return {
                ...ele,
                defaultValue: userDetails?.codeTax,
              };
            case "tel_cell":
              return {
                ...ele,
                defaultValue: userDetails?.mobileNumber,
              };
            case "tel_altro":
              return {
                ...ele,
                defaultValue: userDetails?.otherTelephoneNumber,
              };
            case "email":
              return {
                ...ele,
                defaultValue: userDetails?.email,
              };
            case "indir_resid":
              return {
                ...ele,
                defaultValue:
                  userDetails?.piazzaWay || userDetails?.address?.indir_resid,
              };
            case "civico_resid":
              return {
                ...ele,
                defaultValue:
                  userDetails?.civic || userDetails?.address?.civico_resid,
              };
            case "comune_resid":
              return {
                ...ele,
                defaultValue:
                  userDetails?.city || userDetails?.address?.comune_resid,
              };
            case "cap_resid":
              return {
                ...ele,
                defaultValue:
                  userDetails?.CAP || userDetails?.address?.cap_resid,
              };
            case "prov_resid":
              return {
                ...ele,
                defaultValue:
                  userDetails?.province || userDetails?.address?.prov_resid,
              };
            case "indir_dom":
              return {
                ...ele,
                defaultValue:
                  userDetails?.piazzaWay || userDetails?.address?.indir_dom,
              };
            case "civico_dom":
              return {
                ...ele,
                defaultValue:
                  userDetails?.civic || userDetails?.address?.civico_dom,
              };
            case "comune_dom":
              return {
                ...ele,
                defaultValue:
                  userDetails?.city || userDetails?.address?.comune_dom,
              };
            case "cap_dom":
              return {
                ...ele,
                defaultValue: userDetails?.CAP || userDetails?.address?.cap_dom,
              };
            case "prov_dom":
              return {
                ...ele,
                defaultValue:
                  userDetails?.province || userDetails?.address?.prov_dom,
              };

            default:
              return ele;
          }
        });
        setUpdate({
          ...state,
          questions: JSON.stringify({ components: addedData }),
          sectionNo: state?.sectionNo,
        });
      } else {
        ChangeLable.components[ChangeLable.components.length - 1].label =
          formData?.length === state?.sectionNo
            ? "Salva ed esci"
            : "Salva ed esci";

        setUpdate({
          ...state,
          questions: JSON.stringify(ChangeLable),
          sectionNo: state?.sectionNo,
        });
      }
    }
  }, [state]);

  useEffect(() => {
    if (userDetails?._id) {
      setLoading(true);
      dispatch(getQuestionsFormList({ formId: id, userId: userDetails._id }));
    }
  }, [dispatch, id, userDetails?._id]);

  useEffect(() => {
    return () => {
      dispatch(emptyQuestionsFormDataList());
    };
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state, sectionIndex]);

  const handleSubmitForNext = () => {
    if (formRef?.current?.formio?.components) {
      const components = formRef.current.formio.components;
      var errors = [];
      let hasError = false;

      for (const component of components) {
        if (
          component?.component?.key === "data_presunt" &&
          (component?._data?.data_presunt === "00/00/0000" ||
            component?._data?.data_presunt === "" ||
            component?._data?.data_presunt === undefined)
        ) {
          component.refs.messageContainer.innerHTML =
            "Questa risposta è obbligatoria.";
          component.refs.messageContainer.innerText =
            "Questa risposta è obbligatoria.";
          component.refs.messageContainer.classList.remove("invalid-feedback");

          if (!hasError) {
            hasError = true;
          }
          errors.push(component.refs.messageContainer);
        } else {
          if (component?.component?.key === "data_presunt") {
            if (
              hasError &&
              component?._data?.data_presunt !== "00/00/0000" &&
              component?._data?.data_presunt !== ""
            ) {
              hasError = false;
              errors = [];
            }
          }
        }
      }

      if (hasError && errors.length > 0) {
        let currentIndex = 0;

        function scrollToNextError() {
          if (currentIndex < errors.length) {
            errors[currentIndex].scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            currentIndex++;
            setTimeout(scrollToNextError, 1000); // Adjust delay as needed
          }
        }

        scrollToNextError();
        return; // Prevent further submission if there are errors
      }
      if (
        formRef.current.formio.components[
          formRef.current.formio.components.length - 1
        ]._disabled === false &&
        errors.length === 0 &&
        hasError === false
      ) {
        let data = Object.values(formRef?.current?.formio?._submission?.data);

        function checkConditions(data) {
          let hasValidValue = false;
          let hasInvalidValue = false;
          let hasTrueInObject = false;
          const falseCount = data.filter((data) => data === false).length;

          function checkValue(value) {
            if (typeof value === "boolean") {
              if (value === false && falseCount > 1) {
                hasInvalidValue = true;
              } else {
                hasValidValue = true;
              }
            } else if (typeof value === "string") {
              if (value === "" || value === "00/00/0000" || value === "") {
                hasInvalidValue = true;
              } else {
                hasValidValue = true;
              }
            } else if (typeof value === "object" && value !== null) {
              let objectHasTrue = Object.values(value).some(
                (val) => val === true
              );
              if (objectHasTrue && falseCount <= 1) {
                hasTrueInObject = true;
              }
              for (let key in value) {
                checkValue(value[key]);
              }
            }
          }

          for (let value of data) {
            checkValue(value);
          }

          if (hasTrueInObject) {
            return true;
          } else if (hasValidValue && hasInvalidValue) {
            return "p";
          } else if (hasValidValue) {
            return true;
          } else {
            return false;
          }
        }
        let extraField = {};

        const fields = [
          "p_data_nasc",
          "m_nome",
          "m_cogn",
          "data_presunt",
          "firstTimeFillupForm",
          "m_data_nasc",
          "m_tit_stud",
          "gest_sett",
          "altri_liquidi",
          "Latte_mater",
          "Latte_artif",
          "latte_entrambi",
          "tin",
          "epidurale",
          "gest_day",
          "comune_resid",
          "p_tit_stud",
          "Padre",
          "m_lavoro",
          "q_gravid",
          "gravid_vivi",
          "gravid_vivi_input",
          "gravid_morti",
          "gravid_morti_input",
          "gravid_abort_fino19",
          "gravid_abort_fino19_input",
          "gravid_abort_da20",
          "gravid_abort_da20_input",
          "gravid_ivg",
          "gravid_ivg_input",
          "acqua_pozzo",
          "pozzo_lav",
          "pozzo_bere",
          "pozzo_cucina",
          "pozzo_irrig",
          "pozzo_abbever",
          "pozzo_altro",
          "pozzo_altro_spec",
          "studio",
          "cap",
          "citta",
          "nCivico",
          "provincia",
          "viaPiazza",
          "q_gravid_options",
          "cig",
          "ecig",
          "tabacco",
          "fumo_padre",
          "sesso",
          "folico_dopo",
          "folico_dopo_1",
          "folico_dopo_2",
          "folico_dopo_3",
          "pozzo_lav",
          "pozzo_bere",
          "pozzo_cucina",
          "pozzo_irrig",
          "pozzo_abbever",
          "pozzo_altro",
          "indir_resid",
          "civico_resid",
          "cap_resid",
          "comune_resid",
          "prov_resid",
          "indir_dom",
          "civico_dom",
          "cap_dom",
          "Comune",
          "prov_dom",
          "comune_dom",
          "m_comune",
          "m_naz",
          "email",
          "tel_cell",
          "tel_altro",
          "b_data_nasc",
        ];

        fields.forEach((field) => {
          if (formRef?.current?.formio?._submission?.data?.[field]) {
            extraField[field] = formRef.current.formio._submission.data[field];
          }
        });
        const submitedData = {
          formId: state?.formId,
          userId: userDetails?._id,
          sectionId:
            getformData?.length === 0 ? state?._id : getformData[0]._id,
          answer: {
            formData: JSON.stringify(state?.questions),
            submission: JSON.stringify(formRef.current.formio._submission),
          },
          sectionDate:
            sectionIndex === 0 ? moment(currentData).format("DD/MM/YYYY") : "",
          completedDate:
            formData?.length === state?.sectionNo
              ? moment(currentData).format("DD/MM/YYYY")
              : "",
          isCompleted: formData?.length === state?.sectionNo ? true : false,
          extraField: extraField,
          isBlankField: checkConditions(data),
        };
        const isUpdate = getformData?.length === 0 ? false : true;

        dispatch(
          submitForm({
            submitedData,
            sectionNo: sectionIndex,
            callBack: (nextSectionIndex) => {
              if (formData && formData.length > 0) {
                setState(formData[nextSectionIndex]);
                setSectionIndex(nextSectionIndex);
                // setShowDialog(false);
                if (formData[0]?.masteranswers?.unLock === true) {
                  window.location.reload();
                }
              }
            },
            isUpdate,
          })
        );
        if (
          formData?.length === state?.sectionNo &&
          state.formId === "659cc84e386c34c71ea7139c"
        ) {
          navigate("/complete");
        } else if (
          formData?.length === state?.sectionNo &&
          state.formId === "659e23beafdc7228e1876a0c"
        ) {
          navigate("/completeSecond");
        } else if (
          formData?.length === state?.sectionNo &&
          state.formId === "67092d3ac4dc01733bb40f7a"
        ) {
          navigate("/completeThird");
        }
      }
    }
  };

  const checkProperdata = () => {
    let data = Object.values(formRef?.current?.formio?._submission?.data);

    let hasValidValue = false;
    let hasInvalidValue = false;
    let hasTrueInObject = false;
    const falseCount = data.filter((data) => data === false).length;

    function checkValue(value) {
      if (typeof value === "boolean") {
        if (value === false && falseCount > 1) {
          hasInvalidValue = true;
        } else {
          hasValidValue = true;
        }
      } else if (typeof value === "string") {
        if (value === "" || value === "00/00/0000" || value === "") {
          hasInvalidValue = true;
        } else {
          hasValidValue = true;
        }
      } else if (typeof value === "object" && value !== null) {
        let objectHasTrue = Object.values(value).some((val) => val === true);
        if (objectHasTrue && falseCount <= 1) {
          hasTrueInObject = true;
        }
        for (let key in value) {
          checkValue(value[key]);
        }
      }
    }

    for (let value of data) {
      checkValue(value);
    }

    if (hasTrueInObject) {
      return true;
    } else if (hasValidValue && hasInvalidValue) {
      return "p";
    } else if (hasValidValue) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheck = (data) => {
    const result = checkProperdata(data);

    if (result === "p" || result === false) {
      const userConfirmed = window.confirm(
        "Ci sono dei campi lasciati in bianco, sicura di voler procedere? Puoi andare alla prossima sezione, ma ricorda che più risposte darai maggiore sarà il tuo contributo alla ricerca. Ogni tua azione, ogni questionario che completi, conta!"
      );
      if (userConfirmed) {
        handleSubmitForNext();
      }
    } else {
      handleSubmitForNext();
    }
  };

  useEffect(() => {
    if (indexValue === "10") {
      localStorage.setItem("index", 0);
    }
  }, [indexValue]);

  const handleSubmitForSaveAndExit = () => {
    let data = Object.values(formRef?.current?.formio?._submission?.data);

    function checkConditions(data) {
      let hasValidValue = false;
      let hasInvalidValue = false;
      let hasTrueInObject = false;
      const falseCount = data.filter((data) => data === false).length;

      function checkValue(value) {
        if (typeof value === "boolean") {
          if (value === false && falseCount > 1) {
            hasInvalidValue = true;
          } else {
            hasValidValue = true;
          }
        } else if (typeof value === "string") {
          if (value === "" || value === "00/00/0000" || value === "") {
            hasInvalidValue = true;
          } else {
            hasValidValue = true;
          }
        } else if (typeof value === "object" && value !== null) {
          let objectHasTrue = Object.values(value).some((val) => val === true);
          if (objectHasTrue && falseCount <= 1) {
            hasTrueInObject = true;
          }
          for (let key in value) {
            checkValue(value[key]);
          }
        }
      }

      for (let value of data) {
        checkValue(value);
      }

      if (hasTrueInObject) {
        return true;
      } else if (hasValidValue && hasInvalidValue) {
        return "p";
      } else if (hasValidValue) {
        return true;
      } else {
        return false;
      }
    }
    let extraField = {};

    const fields = [
      "p_data_nasc",
      "m_nome",
      "m_cogn",
      "data_presunt",
      "firstTimeFillupForm",
      "m_data_nasc",
      "m_tit_stud",
      "gest_sett",
      "altri_liquidi",
      "Latte_mater",
      "Latte_artif",
      "latte_entrambi",
      "tin",
      "epidurale",
      "gest_day",
      "comune_resid",
      "p_tit_stud",
      "Padre",
      "m_lavoro",
      "q_gravid",
      "gravid_vivi",
      "gravid_vivi_input",
      "gravid_morti",
      "gravid_morti_input",
      "gravid_abort_fino19",
      "gravid_abort_fino19_input",
      "gravid_abort_da20",
      "gravid_abort_da20_input",
      "gravid_ivg",
      "gravid_ivg_input",
      "acqua_pozzo",
      "pozzo_lav",
      "pozzo_bere",
      "pozzo_cucina",
      "pozzo_irrig",
      "pozzo_abbever",
      "pozzo_altro",
      "pozzo_altro_spec",
      "studio",
      "cap",
      "citta",
      "nCivico",
      "provincia",
      "viaPiazza",
      "q_gravid_options",
      "cig",
      "ecig",
      "tabacco",
      "fumo_padre",
      "sesso",
      "folico_dopo",
      "folico_dopo_1",
      "folico_dopo_2",
      "folico_dopo_3",
      "pozzo_lav",
      "pozzo_bere",
      "pozzo_cucina",
      "pozzo_irrig",
      "pozzo_abbever",
      "pozzo_altro",
      "indir_resid",
      "civico_resid",
      "cap_resid",
      "comune_resid",
      "prov_resid",
      "indir_dom",
      "civico_dom",
      "cap_dom",
      "Comune",
      "prov_dom",
      "comune_dom",
      "m_comune",
      "m_naz",
      "email",
      "tel_cell",
      "tel_altro",
      "b_data_nasc",
    ];
    fields.forEach((field) => {
      if (formRef?.current?.formio?._submission?.data?.[field]) {
        extraField[field] = formRef.current.formio._submission.data[field];
      }
    });
    const submitedData = {
      formId: state?.formId,
      userId: userDetails?._id,
      sectionId: getformData?.length === 0 ? state?._id : getformData[0]._id,
      answer: {
        formData: JSON.stringify(state?.questions),
        submission: JSON.stringify(formRef.current.formio._submission),
      },
      sectionDate:
        sectionIndex === 0 ? moment(currentData).format("DD/MM/YYYY") : "",
      completedDate:
        formData?.length === state?.sectionNo
          ? moment(currentData).format("DD/MM/YYYY")
          : "",
      isCompleted: formData?.length === state?.sectionNo ? true : false,
      extraField: extraField,
      isBlankField: checkConditions(data),
    };
    const isUpdate = getformData?.length === 0 ? false : true;
    dispatch(
      submitForm({
        submitedData,
        sectionNo: sectionIndex,
        callBack: (nextSectionIndex) => {
          if (formData && formData.length > 0) {
            setState(formData[nextSectionIndex]);
            setSectionIndex(nextSectionIndex);
          }
        },
        isUpdate,
      })
    );
    navigate("/complete-save-from");
  };

  useEffect(() => {
    setTimeout(() => {
      updateFormioComponent(formRef);
    }, 2000);
  }, [Update]);

  useEffect(() => {
    const submitedData = {
      formId: state?.formId,
      sectionId: state?._id,
      userId: userDetails?._id,
    };

    dispatch(
      getsubmitForm({
        submitedData,
      })
    );
  }, [state]);

  const handlePreviousSection = () => {
    if (sectionIndex > 0) {
      const previousIndex = sectionIndex - 1;
      if (previousIndex === 0) {
        localStorage.setItem("index", -1);
      } else {
        localStorage.setItem("index", previousIndex);
      }
      setState(formData[previousIndex]);
      setSectionIndex(previousIndex);
      window.location.reload();
    }
  };

  const changeTitleButton = questionariesList?.data?.find((item) => {
    return item?._id === state?.formId;
  });

  const handleChange = (event) => {
    if (
      event.changed &&
      event.changed.component &&
      event.changed.component.key
    ) {
      if (
        event.changed.component.key === "padre" &&
        event.changed.value === "si"
      ) {
        const components = formRef.current.formio.components;

        for (const component of components[1]?.components) {
          if (
            component?.component?.key === "m_data_nasc" ||
            component?.component.key === "p_data_nasc" ||
            component?.component?.key === "qualELaDataPresuntaDelParto" ||
            component?.component?.key === "data_mestr" ||
            component?.component?.key === "data_presunt"
          ) {
            component._months = italianMonths;
            formRef.current.formio.redraw();
          }
        }
      }
      // Add your custom logic here
    }
  };

  return (
    <>
      {loading ? ( // Show loader while loading is true
        <Loader />
      ) : (
        <div className="option-detail">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="left-side left-side-content">
                  <div className="left-side-inner">
                    <ul className="five-step">
                      {formData &&
                        formData?.map((ele, index) => (
                          <li key={index}>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                `/images/c${formData?.length}-1-${
                                  ele.isCompleted &&
                                  ele?.sectionNo !== state?.sectionNo
                                    ? "darkgreen"
                                    : ele?.sectionNo === state?.sectionNo
                                    ? "lightgreen"
                                    : "gray"
                                }.svg`
                              }
                              alt="Progress"
                              className={`c${formData?.length}-${ele?.sectionNo}`}
                            />
                          </li>
                        ))}
                    </ul>
                    <img
                      src={formData[0]?.image}
                      alt="check"
                      className="check_pregnance"
                    />
                  </div>

                  <h2>{state?.sectionName}</h2>
                </div>
              </div>
              {(Update?.sectionNo &&
                Update?.questions &&
                Update?.sectionNo === state?.sectionNo) ||
              Update?.masteranswers?.unLock === true ? (
                <div className="col-lg-8">
                  <div className="right-side customformcss next-custom-button">
                    {getformData.length === 0 ? (
                      <Form
                        ref={formRef}
                        form={
                          Update?.questions ? JSON.parse(Update?.questions) : {}
                        }
                        onSubmit={handleSubmitForSaveAndExit}
                        onChange={handleChange}
                      />
                    ) : (
                      <Form
                        ref={formRef}
                        form={JSON.parse(
                          JSON.parse(getformData[0]?.answer?.formData)
                        )}
                        submission={JSON.parse(
                          getformData[0]?.answer?.submission
                        )}
                        onChange={handleChange}
                        onSubmit={handleSubmitForSaveAndExit}
                      />
                    )}
                  </div>
                  <div className="prossima">
                    <button
                      className="solid-btn bg-color"
                      onClick={handleCheck}
                    >
                      {changeTitleButton?.sectionNo - 1 === parseInt(indexValue)
                        ? t("Invia questionario")
                        : t("Prossima sezione")}
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="56"
                          height="17"
                          viewBox="0 0 56 17"
                          fill="none"
                        >
                          <path
                            d="M25 4L30 9L25 14"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                  <div
                    className="prev-questionaries"
                    style={{
                      display: state?.sectionNo === 1 ? "none" : "block",
                    }}
                  >
                    <button onClick={handlePreviousSection}>
                      Torna alla sezione precedente
                    </button>
                  </div>
                  <div className="empty-prossima"></div>
                </div>
              ) : (
                <div className="col-lg-8">
                  <div className="right-side text-center">
                    <h3>{t("Questionnaire loading!")}</h3>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* <CustomDialog
        show={showDialog}
        handleClose={handleClose}
        onNext={handleSubmitForNext}
        onPrev={handlePrev}
      /> */}
    </>
  );
};

export default withTranslation()(FormRender);
