import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../../redux/auth/authAction";

const SignIn = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const [errorInput, setErrorInput] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState({
    password: false,
  });

  const togglePasswordVisibility = (field) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [field]: !prevShowPassword[field],
    }));
  };

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setErrorInput((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: "",
    }));
  };

  const handleNavigate = () => {
    navigate("/auth/privacy");
  };

  const handleForgetPass = () => {
    navigate("/auth/forget-password");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (state.email === "" || state.password === "") {
      setErrorInput((prevErrors) => ({
        ...prevErrors,
        email: state.email === "" ? t("*Campo obbligatorio") : "",
        password: state.password === "" ? t("*Campo obbligatorio") : "",
      }));
    } else {
      dispatch(
        login({
          state,
          callBack: () => {
            navigate("/");
            localStorage.setItem("lang", "it");
          },
        })
      );
    }
  };

  return (
    <div className="register-detail signin-section">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {/* <div className="col-lg-6">
           
          </div> */}
          <div className="col-md-6 col-lg-6">
            <div className="text-center register_title_section">
              <div className="meditation-yaga-section">
                <h2>{t("Accedi")}</h2>
              </div>
            </div>
            <form method="POST">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 register-sections desktop-details">
                  <div
                    className={`form-group register-field login-field ${
                      errorInput.email ? "error-content" : ""
                    }`}
                  >
                    <div className="error">{t(errorInput?.email)}</div>
                    <label>{t("Email")}</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={state?.email || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div
                    className={`form-group register-field login-field ${
                      errorInput.password ? "error-content" : ""
                    }`}
                    style={{ position: "relative" }}
                  >
                    <div className="error">{t(errorInput?.password)}</div>
                    <label>Password</label>

                    <div style={{ position: "relative" }}>
                      <input
                        type={showPassword?.password ? "text" : "password"}
                        name="password"
                        className="form-control"
                        value={state?.password || ""}
                        onChange={handleChange}
                        style={{ paddingRight: "40px" }} // Ensures text doesn't overlap with the icon
                      />
                      <i
                        className={
                          !showPassword?.password
                            ? "ri-eye-off-line"
                            : "ri-eye-line"
                        }
                        onClick={() => togglePasswordVisibility("password")}
                        style={{
                          position: "absolute",
                          right: "10px", // Adjust the right position as needed
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                          fontSize: "20px",
                          color: "#888", // Optional: Set the icon color
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="btn-section text-center">
                  <button className="solid-btn" onClick={handleSubmit}>
                    {t("Procedi")}
                  </button>
                  <div className="linkcontent">
                    <button onClick={handleForgetPass}>
                      {t("Password dimenticata")}
                    </button>
                  </div>
                </div>

                <div className="text-center">
                  <h3 style={{ fontSize: "30px" }}>
                    Non ti sei ancora registrata?
                  </h3>
                  <button className="solid-btn" onClick={handleNavigate}>
                    Registrati
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SignIn);
